<template>
  <div class="page-container">
    <div class="card-container">
      <div class="header">
        <img :src="logoIcon" width="98" alt="" class="logo" />
        <div class="title">{{ translateTitle("安全验证") }}</div>
        <div class="send-type" v-if="currentVerify !== 'google'">
          {{
            translateTitle(
              currentVerify === "email"
                ? "验证码发送至邮箱"
                : "验证码发送至手机"
            )
          }}
        </div>
        <div v-if="currentVerify && currentVerify !== 'google'" class="account">
          {{
            currentVerify === "email"
              ? accountInfo["email"]
              : accountInfo["mobile"]
          }}
        </div>
      </div>
      <div class="form">
        <el-form>
          <el-form-item
            :label="translateTitle('谷歌验证')"
            v-if="currentVerify === 'google'"
          >
            <el-input
              v-model="googleCode"
              type="tel"
              maxlength="6"
              @input="autoVerify"
            >
              <template #suffix v-if="currentVerify === 'google'">
                <span class="send-code-btn" @click="onPasteGoogleCode">{{
                  translateTitle("粘贴")
                }}</span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item
            :label="translateTitle('手机验证')"
            v-else-if="currentVerify === 'phone'"
          >
            <el-input
              v-model="phoneCode"
              type="tel"
              maxlength="6"
              @input="autoVerify"
            >
              <template #suffix v-if="currentVerify === 'phone'">
                <span
                  class="send-code-btn"
                  v-if="
                    phoneCountDown === 0 || phoneCountDown === -1 || !isSendCode
                  "
                  @click="getPhoneVerifyCode"
                  >{{ translateTitle("获取验证码") }}</span
                >
                <el-popover
                  v-else
                  v-model="showPopover"
                  popper-class="time-popover"
                  trigger="click"
                  placement="bottom-end"
                >
                  <div class="countdown-tip">
                    {{
                      translateTitle("@秒后重新发送验证码有效期为30分钟", [
                        phoneCountDown,
                      ])
                    }}
                  </div>
                  <template #reference>
                    <div class="send-success">
                      <span class="code-text">{{
                        translateTitle("验证码已发送")
                      }}</span>
                      <img :src="warnIcon" width="15" alt="" />
                    </div>
                  </template>
                </el-popover>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item
            :label="translateTitle('邮箱验证')"
            v-else-if="currentVerify === 'email'"
          >
            <el-input
              v-model="emailCode"
              type="tel"
              maxlength="6"
              @input="autoVerify"
            >
              <template #suffix v-if="currentVerify === 'email'">
                <span
                  class="send-code-btn"
                  v-if="
                    emailCountDown === 0 ||
                    emailCountDown === -1 ||
                    !isSendEmailCode
                  "
                  @click="getEmailVerifyCode"
                  >{{ translateTitle("获取验证码") }}</span
                >
                <el-popover
                  v-else
                  v-model="showEmailPopover"
                  popper-class="time-popover"
                  trigger="click"
                  placement="bottom-end"
                >
                  <div class="countdown-tip">
                    {{
                      translateTitle("@秒后重新发送验证码有效期为30分钟", [
                        emailCountDown,
                      ])
                    }}
                  </div>
                  <template #reference>
                    <div class="send-success">
                      <span class="code-text">{{
                        translateTitle("验证码已发送")
                      }}</span>
                      <img :src="warnIcon" width="15" alt="" />
                    </div>
                  </template>
                </el-popover>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
        <el-button class="next-btn" type="primary" @click="gotoSubmitVerify">
          <span v-if="!loading">{{ translateTitle("下一步") }}</span>
          <Loading v-else />
        </el-button>

        <div
          class="other-btn"
          v-if="openList.length > 1"
          @click="showVerifyList = true"
        >
          {{ translateTitle("切换验证方式") }}
        </div>

        <div class="other-btn" @click="showNotCodeDialog = true">
          {{ translateTitle("未收到验证码 ?") }}
        </div>
      </div>

      <transition name="fade">
        <div class="card-container choose-verify" v-show="showVerifyList">
          <div class="header">
            <img :src="logoIcon" width="98" alt="" class="logo" />
            <div class="title">{{ translateTitle("安全验证") }}</div>
          </div>
          <div class="verify-list">
            <div
              v-for="item in openList"
              :key="item.type"
              class="verify-item"
              @click="toggleType(item)"
            >
              <img :src="item.icon" width="36" alt="" />
              <span>{{ translateTitle(item.label) }}</span>
              <CheckIcon v-if="item.type === currentVerify" />
              <ArrowRightIcon v-else />
            </div>
          </div>
        </div>
      </transition>
    </div>

    <NotCodeDialog
      :visible="showNotCodeDialog"
      @input="val => (showNotCodeDialog = val)"
      :isMobile="currentVerify === 'phone'"
    />
  </div>
</template>

<script>
import logoIcon from "@/assets/img/users/logo.png";
import checkIcon from "@/assets/img/users/check.png";
import googleIcon from "@/assets/img/users/google.png";
import mobileIcon from "@/assets/img/users/mobile.png";
import emailIcon from "@/assets/img/users/email.png";
import CheckIcon from "@/components/icons/CheckIcon.vue";
import { UCenterRequest } from "@/model/UCenterService";
import warnIcon from "@/assets/img/users/warn.png";
import Loading from "@/components/common/Loading.vue";
import ArrowRightIcon from "@/components/icons/ArrowRightIcon.vue";
import NotCodeDialog from "./not-code.vue";
import Cookies from "js-cookie";
import * as Account from "@/model/user/account";
export default {
  components: {
    CheckIcon,
    Loading,
    ArrowRightIcon,
    NotCodeDialog,
  },
  data() {
    let tokenVerify = {};
    const query = this.$route.query;
    if (query.tokenVerify) {
      tokenVerify = JSON.parse(window.decodeURIComponent(query.tokenVerify));
    }
    return {
      logoIcon,
      checkIcon,
      warnIcon,
      emailCountDown: -1,
      phoneCountDown: -1,
      googleCode: "",
      phoneCode: "",
      emailCode: "",
      tokenVerify: tokenVerify,
      from: this.$route.query.from || "",
      account: this.$route.query.account || "",
      currentVerify: this.$route.query.from || "",
      isSendCode: false,
      showPopover: false,
      isSendEmailCode: false,
      showEmailPopover: false,
      loading: false,
      showVerifyList: false,
      showNotCodeDialog: false,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    authInfo() {
      const authInfo = this.$route.params.authInfo || {};
      return authInfo;
    },
    accountInfo() {
      const accountInfo = this.$route.params.accountInfo || {};
      return accountInfo;
    },
    minVerifyNum() {
      const authInfo = this.$route.params.authInfo || {};
      return authInfo.min_verify_num || 1;
      // const authInfo = this.authInfo ? this.authInfo : this.userInfo.authInfo;
      // return authInfo.min_verify_num || 1;
    },
    allVerifyList() {
      return [
        {
          label: this.translateTitle("谷歌验证"),
          icon: googleIcon,
          type: "google",
        },
        {
          label: this.translateTitle("手机验证"),
          icon: mobileIcon,
          type: "phone",
        },
        {
          label: this.translateTitle("邮箱验证"),
          icon: emailIcon,
          type: "email",
        },
      ];
    },
    openList() {
      const authInfo = this.$route.params.authInfo || {};
      const list = this.allVerifyList.filter(item => {
        if (item.type === "google" && authInfo.open_google === 0) return false;
        if (item.type === "phone" && authInfo.open_mobile === 0) return false;
        if (item.type === "email" && authInfo.open_email === 0) return false;
        return true;
      });
      return list;
    },
    needVerifyList() {
      return this.openList.slice(0, this.minVerifyNum);
    },
    requestType() {
      // 类型:
      // 0-未知
      // 1-绑定GOOGLE|2-换绑GOOGLE
      // 3-绑定手机|4-换绑手机
      // 5-绑定邮箱|6-换绑邮箱
      // 7-绑定通行密钥|8-删除通行密钥
      // 9-关闭GOOGLE|10-开启GOOGLE
      // 11-登陆|12-登陆密码修改|13-账号注销|14-找回密码
      // 21-提币|22-开启支付二次验证|23-关闭支付二次验证|24-设置支付密码|25-修改支付密码|26-修改二次验证限额|27-重置交易密码
      // 31-删除设备|32-关闭设备
      let type = 0;
      const source = this.$route.query.source;
      if (source === "bindGoogle") {
        type = 1;
      } else if (source === "editGoogle") {
        type = 2;
      } else if (source === "bindMobile") {
        type = 3;
      } else if (source === "editMobile") {
        type = 4;
      } else if (source === "bindEmail") {
        type = 5;
      } else if (source === "editEmail") {
        type = 6;
      } else if (source === "openGoogle") {
        type = 9;
      } else if (source === "closeGoogle") {
        type = 10;
      } else if (source === "login") {
        type = 11;
      } else if (source === "editPassword") {
        type = 12;
      } else if (source === "deleteAccount") {
        type = 13;
      } else if (source === "forgetPassword") {
        type = 14;
      } else if (source === "setPaymentPassword") {
        type = 24;
      } else if (source === "editPaymentPassword") {
        type = 25;
      } else if (source === "resetPaymentPassword") {
        type = 27;
      }
      return type;
    },
  },
  created() {},
  methods: {
    toggleType(data) {
      this.currentVerify = data.type;
      this.showVerifyList = false;
    },
    async onPasteGoogleCode() {
      try {
        const clip = await navigator.clipboard.readText();
        this.googleCode = clip;
      } catch (e) {}
    },
    handleCountDown() {
      this.emailCountDown = 60;
      const timer = () => {
        this.emailCountdownTimer = setTimeout(() => {
          this.emailCountDown--;
          if (this.emailCountDown > 0) {
            timer();
          } else {
            this.emailCountdownTimer && clearTimeout(this.emailCountdownTimer);
            this.emailCountDown = -1;
            this.showEmailPopover = false;
          }
        }, 1000);
      };
      timer();
    },
    handlePhoneCountDown() {
      this.phoneCountDown = 60;
      const timer = () => {
        this.phoneCountdownTimer = setTimeout(() => {
          this.phoneCountDown--;
          if (this.phoneCountDown > 0) {
            timer();
          } else {
            this.phoneCountdownTimer && clearTimeout(this.phoneCountdownTimer);
            this.phoneCountDown = -1;
            this.showPopover = false;
          }
        }, 1000);
      };
      timer();
    },
    async getEmailVerifyCode() {
      if (this.emailCountDown > 0) return;
      this.isSendEmailCode = false;
      this.handleCountDown();
      const res = await UCenterRequest.getVerityEmailCode({
        type: this.requestType,
        token: this.$route.params.token,
      });
      this.isSendEmailCode = true;
      if (res.status === 200) {
        this.$nextTick(() => {
          this.showEmailPopover = true;
          setTimeout(() => {
            this.showEmailPopover = false;
          }, 3000);
        });
      }
    },
    async getPhoneVerifyCode() {
      if (this.phoneCountDown > 0) return;
      this.handlePhoneCountDown();
      this.isSendCode = false;
      const res = await UCenterRequest.getVerifyMobileCode({
        type: this.requestType,
        token: this.$route.params.token,
      });
      this.isSendCode = true;
      if (res.status === 200) {
        this.$nextTick(() => {
          this.showPopover = true;

          setTimeout(() => {
            this.showPopover = false;
          }, 3000);
        });
      }
    },
    autoVerify(val) {
      if (val.length === 6) {
        setTimeout(() => {
          this.gotoSubmitVerify();
        }, 500);
      }
    },
    async handleVerifyCallback(query, params) {
      const source = this.$route.query.source;
      if (source === "forgetPassword") {
        for (const key in query) {
          if (typeof query[key] === "object") {
            query[key] = window.encodeURIComponent(JSON.stringify(query[key]));
          }
        }
        this.$router.push({
          name: "ResetPassword",
          query: {
            ...this.$route.query,
            ...(query || {}),
          },
          params: {
            ...this.$route.params,
            ...(params || {}),
          },
        });
      } else if (source === "login") {
        this.loading = true;
        const area = this.$route.query.area;
        const res = await UCenterRequest.checkSafetyVerify({
          login_name: this.$route.query.account,
          token_login: this.$route.query.tokenLogin,
          token_verify: query.tokenVerify[this.currentVerify],
          area,
        });
        if (res.status === 200) {
          const loginRes = await UCenterRequest.login({
            login_name: this.$route.query.account,
            token_login: this.$route.query.tokenLogin,
            area,
          });

          if (loginRes.status === 200) {
            const userInfo = loginRes.data;
            const { tokenInfo } = userInfo;
            this.$sensors.login(userInfo.user_id);
            Cookies.set("topcredit_exchange_t", tokenInfo.token);

            sessionStorage.setItem("topcredit_exchange_t", tokenInfo.token);

            Account.writeUserInfo(userInfo, true);
            this.$store.dispatch("user/getUserInfo").then(res => {
              let jump = this.$route.params.jump;
              if (jump) {
                //by TZ bakUrl貌似又有问题了。我这里解决的是bakUrl=true/false的问题。
                location.href = jump;
                return;
              } else {
                window.location.href = "/";
              }
            });
          }
        }
        this.loading = false;
      }
    },
    async gotoSubmitVerify() {
      if (this.loading) return;
      if (this.currentVerify === "phone" && !this.phoneCode) {
        this.$message(this.translateTitle("请输入手机验证码"));
        return;
      }
      if (this.currentVerify === "email" && !this.emailCode) {
        this.$message(this.translateTitle("请输入邮箱验证码"));
        return;
      }
      if (this.currentVerify === "google" && !this.googleCode) {
        this.$message(this.translateTitle("请输入谷歌验证码"));
        return;
      }
      const type = this.requestType;

      this.loading = true;
      let res;
      const body = {
        type,
      };
      if (this.$route.params.token) {
        body.token = this.$route.params.token;
      }
      if (this.currentVerify === "phone" && this.phoneCode) {
        res = await UCenterRequest.safetyVerifyPhoneCode({
          ...body,
          mobile_code: this.phoneCode,
        });
        if (res.status !== 200) {
          this.phoneCode = "";
        }
      }
      if (this.currentVerify === "email" && this.emailCode) {
        res = await UCenterRequest.safetyVerifyEmailCode({
          ...body,
          email_code: this.emailCode,
        });
        if (res.status !== 200) {
          this.emailCode = "";
        }
      }
      if (this.currentVerify === "google" && this.googleCode) {
        res = await UCenterRequest.safetyVerifyGoogleCode({
          ...body,
          google_code: this.googleCode,
        });
        if (res.status !== 200) {
          this.googleCode = "";
        }
      }

      if (res.status === 200) {
        this.tokenVerify[this.currentVerify] = res.data.token_verify;
        const minVerifyNum = this.$route.query.minVerifyNum;
        // if (this.from !== this.currentVerify && tokenVerify[this.from]) {
        //   delete tokenVerify[this.from];
        // }
        if (Object.keys(this.tokenVerify).length >= minVerifyNum) {
          this.handleVerifyCallback({
            tokenVerify: this.tokenVerify,
          });
          return;
        }
        this.$router.push({
          name: "SafetyVerify",
          query: {
            ...this.$route.query,
            tokenVerify: window.encodeURIComponent(
              JSON.stringify(this.tokenVerify)
            ),
          },
          params: {
            ...this.$route.params,
          },
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/common.scss";
.account {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  margin-top: 12px;
}
.card-container {
  height: auto !important;
  min-height: 520px;
}
.next-btn {
  margin-top: 78px !important;
}
.other-btn {
  text-align: left !important;
}
.tip {
  margin-top: 32px;
  color: var(---c_t02, #5c5f69);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
}
.step {
  color: #0d0e0e;

  font-family: "PingFang SC";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 150% */
  span {
    color: var(--7-c_m, #3348f6);
  }
}

.verify-item {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: rgba(52, 106, 245, 0.06);
  width: 335px;
  height: 68px;
  margin-bottom: 16px;
  padding: 0 20px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
  span {
    margin-left: 10px;
    margin-right: auto;
    color: var(--7-c_t01, #0d0e0e);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 131.25% */
  }
}
.verify-list {
  /* padding: 10px 20px; */
  ::v-deep {
    .el-dropdown-menu__item {
      margin-block-start: 12px;
      &:hover {
        background-color: unset;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.send-type {
  color: var(---c_t02, #5c5f69);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 33px;
}
.account {
  margin-top: 8px;
}
.card-container {
  position: relative;
  .choose-verify {
    position: absolute;
    z-index: 1;
    border-radius: inherit;
    background-color: #fff;
    inset: 0;
    border: none;
    width: auto;
    .verify-list {
      margin-top: 50px;
    }
  }
}
</style>

<style lang="scss">
.time-popover {
  border-radius: 6px;
  background: rgba(69, 69, 69, 0.86);
  box-shadow: none !important;
  transform: translateX(10px);

  .countdown-tip {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    max-width: 233px;
  }
  .popper__arrow {
    border-bottom-color: transparent;
    filter: none !important;
    left: calc(100% - 22px) !important;

    &::after {
      top: 0 !important;
      border-bottom-color: rgba(69, 69, 69, 0.86) !important;
    }
  }
}
</style>
